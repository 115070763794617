
body {
    background-image: url("../images/backgrounds/sky.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

canvas {
    position: static;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
}

@media (max-width: 768px ) {
    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}