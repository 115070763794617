body {
  background-image: url("sky.d957a8ca.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

canvas {
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  position: static;
}

@media (width <= 768px) {
  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*# sourceMappingURL=index.11c49142.css.map */
